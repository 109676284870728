<template>
    <div class="  is-fullheight sticky-sidebar" >
      <aside class="menu column  aside   is-fullheight" >
       <p class="menu-label">
Bookings
      </p>
       <ul class="menu-list">
         <li>
           <a class="">General</a>
           <ul>
             <li @click="activeMenuLink = 'bookings'" style="display:none"><router-link to="/bookings"  :class="{'is-active':activeMenuLink === 'bookings'}">Dashboard</router-link></li>
             <li @click="activeMenuLink = 'bookings/new'"><router-link to="/bookings/new"  :class="{'is-active':activeMenuLink === 'bookings/new'}">New Bookings</router-link></li>
             <li @click="activeMenuLink = 'bookings/pending'" style="display:none"><router-link to="/bookings/pending"  :class="{'is-active':activeMenuLink === 'bookings/pending'}">Pending (Not Needed)</router-link></li>
             <li @click="activeMenuLink = 'bookings/assigned'"><router-link to="/bookings/assigned"  :class="{'is-active':activeMenuLink === 'bookings/assigned'}">Assigned</router-link></li>
             <li @click="activeMenuLink = 'bookings/confirmed'"><router-link to="/bookings/confirmed"  :class="{'is-active':activeMenuLink === 'bookings/confirmed'}">Confirmed</router-link></li>
             <li @click="activeMenuLink = 'bookings/cancelled'"><router-link to="/bookings/cancelled"  :class="{'is-active':activeMenuLink === 'bookings/cancelled'}">Cancelled</router-link></li>
             <li @click="activeMenuLink = 'bookings/search'"><router-link to="/bookings/search"  :class="{'is-active':activeMenuLink === 'bookings/search'}">Find A Booking</router-link></li>
             
           </ul>
         </li>

         <li>
           <a class="">Create</a>
           <ul>
             <li @click="activeMenuLink = 'bookings/add'"><router-link to="/bookings/add"  :class="{'is-active':activeMenuLink === 'bookings/add'}">Create a Manual Booking</router-link></li>
             <li @click="activeMenuLink = 'bookings/add-block'"><router-link to="/bookings/add-block"  :class="{'is-active':activeMenuLink === 'bookings/add-block'}">Create Block Booking</router-link></li>
             
           </ul>
         </li>
         <li>
           <a class="">Admin</a>
           <ul>
            <li @click="activeMenuLink = '/patients'"><router-link to="/patients"  :class="{'is-active':activeMenuLink === '/patients'}">Community Patients</router-link></li>
            <li @click="activeMenuLink = '/billing/client-groups'"><router-link to="/billing/client-groups"  :class="{'is-active':activeMenuLink === '/billing/client-groups'}">Client/Group Config</router-link></li>
            <li @click="activeMenuLink = '/holidays'"><router-link to="/holidays"  :class="{'is-active':activeMenuLink === '/holidays'}">Holiday Calendar</router-link></li>
           </ul>
         </li>

         <li>
           <a class="">Reports</a>
           <ul>
            <li @click="activeMenuLink = 'bookings/monthly-stats'"><router-link to="/bookings/monthly-stats"  :class="{'is-active':activeMenuLink === 'bookings/monthly-stats'}">Confirmed Monthly Stats</router-link></li>
            <li @click="activeMenuLink = 'bookings/monthly-stats-all'"><router-link to="/bookings/monthly-stats-all"  :class="{'is-active':activeMenuLink === 'bookings/monthly-stats-all'}">All Monthly Stats</router-link></li>
            <li @click="activeMenuLink = 'bookings/availability-report'"><router-link to="/bookings/availability-report"  :class="{'is-active':activeMenuLink === 'bookings/availability-report'}">Availability Report</router-link></li>
            <li @click="activeMenuLink = 'bookings/booking-dates-report'"><router-link to="/bookings/booking-dates-report"  :class="{'is-active':activeMenuLink === 'bookings/booking-dates-report'}">Booking Types Report</router-link></li>
           </ul>
         </li>
         
         
         
       </ul>
       
     </aside>
     
    </div>
 </template>
 
 <script>
 export default {
   name: 'SideBarBookings',
   components: {
   },
   data() {
     return { activeMenuLink: null }
   },
   mounted() {
     this.activeMenuLink = this.$route.path.substring(1)
     //this.activeMenuLink = this.$route.path
     console.log(this.activeMenuLink)
   }
 }
 
 </script>
<script>
import NavBar from "@/components/NavBar";

export default {
  components: {
    NavBar,
   
  }
}
</script>

<template>
    <div>
        <nav-bar />

        <section class="  is-fullheight">
        
        <div class="   mt-0">
            <div class="section">
                <router-view/>
            </div>
        </div>
        </section>

    </div>

</template>
<template>
  <div class="home mt-0">

    <div v-if="loadinguser==true" class="notification is-warning">
      Loading user details ... 
    </div>

    <div class="columns buttons are-large" v-if="loadinguser==false">
      <div class="column is-full ">
        <router-link to="/applications"><button class="button is-info is-fullwidth" >Applications</button></router-link>
      </div>  
      <div class="column is-full ">
        <router-link to="/bookings/new"><button class="button is-info is-fullwidth" >Bookings</button></router-link>
      </div>  
      <div class="column is-full ">
        <router-link to="/members"><button class="button is-info is-fullwidth" >Members</button></router-link>
      </div> 
      <div class="column is-full " v-if="user.billing==1">
        <router-link to="/billing"><button class="button is-info is-fullwidth" >Billing</button></router-link>
      </div>  

      <div class="column is-full " style="" >
        <router-link to="/tickets/my-tickets"><button class="button is-warning is-fullwidth" >Tickets</button></router-link>
      </div>  
      
      <div class="column is-full"><button class="button is-danger is-fullwidth" @click="logout()">Logout</button></div>
        
      </div>

      
    
  </div>
</template>

<script>
import MemberMixin from '@/mixins/MemberMixin.js';

export default {
  name: 'Home',
  components: {
    
  },
  mixins: [MemberMixin],
  data: function () {
        return {
                
                API_NAME: process.env.VUE_APP_API_NAME,
                accessToken: '',
                user: [],
                loadinguser: true
               
        }
    },
  methods: {
          logout() {
              if(confirm('Are you sure you want to logout ?'))
              {
                  this.$auth.logout({
                  returnTo: window.location.origin
                });
              }
      },
      
    },
    created() {
          this.getAccessToken()
            .then(() => { 
                this.getUserSelf()
            });
          
        },
}
</script>
